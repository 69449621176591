import React from 'react';
import { AppBar, AppBarProps } from 'react-admin';

import UserMenu from "./UserMenu"
import {  Typography } from '@mui/material';

const classes = ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
})

export default function MyAppBar(props: AppBarProps) {
    return (
      <AppBar {...props} userMenu={<UserMenu />} color="primary" sx={{ "& .MuiBox-root": { display: "none" } }}>
        <Typography variant="h6" color="inherit" sx={classes.title} id="react-admin-title" />
      </AppBar>
    );
}
