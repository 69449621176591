import { AutocompleteInput, Datagrid, DateField, Filter, List, NumberInput, Pagination, ReferenceManyField, ResettableTextField, SelectArrayInput, SelectInput, SimpleForm, Tab, TabbedForm, Toolbar } from "react-admin"
import { timeOptions } from "../lib/consts"


function extend(klass: any, props: any) {
  klass.defaultProps = Object.assign(klass.defaultProps || {}, props)
}

export function applyDefaults() {
  extend(SimpleForm, { variant: "standard" })
  extend(TabbedForm, { variant: "standard" })
  extend(AutocompleteInput, { variant: "standard", })
  extend(Filter, { variant: "standard" })
  extend(ResettableTextField, { variant: "standard" })
  extend(SelectArrayInput, { variant: "standard" })  
  extend(NumberInput, { variant: "standard" })
  extend(Toolbar, { margin: "normal" })
  extend(Tab, { contentClassName: "tabContent" })
  extend(Pagination, { rowsPerPageOptions: [], perPage: 50 })
  extend(ReferenceManyField, { perPage: 50 })
  extend(List, { perPage: 50 })
  extend(SelectInput, { emptyText: '\u00A0' })
  extend(Datagrid, { bulkActionButtons: false })
  extend(DateField, { showTime: true, options: timeOptions })
} 

