import React from "react"
import { LayoutProps, Layout } from "react-admin";
import Menu from "./Menu"
import AppBar from "./AppBar"

export default function MyLayout(props: LayoutProps) {
  return <Layout {...props} appBar={AppBar} menu={Menu} />
}


