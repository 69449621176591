const timeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }

export type UserType = "mdc"  | "client"
export type Role = {id: string, name: string, key?: string}

const roles: Record<UserType, Role[]> = {
  mdc: [
    { id: "admin", name: "Super User", key: "admin" },
    { id: "viewer", name: "Viewer", key: "viewer" },
    { id: "activator", name: "Activator", key: "activator" },
  ],
  client: [
    { id: "admin", name: "Client Admin" },
    { id: "viewer", name: "Viewer" },
    { id: "activator", name: "Activator" },
  ]
}
const userTypes = [
  { id: "mdc", name: "userTypes.mdc", key: "mdc" },
  { id: "client", name: "userTypes.client", key: "client" }
]
export {
  timeOptions,
  roles,
  userTypes
}
