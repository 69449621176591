import { Datagrid, DateField, FunctionField, RaRecord, ReferenceField, ReferenceManyField, Tab, TabbedShowLayout, TextField, useRecordContext } from "react-admin"
import { Show } from "../../components/DefaultDetails"
import { Typography } from "@mui/material";
import ActivateSwitch from "../Common/ActivateSwitch";

function AddressField({source}: {source: string, label?: string | boolean}) {
  const record = useRecordContext()
  const {city, region, street, house} = record[source] ?? {}
  return (
    <Typography variant="body2">{`${city} (${region}), ${street}, ${house}`}</Typography>
  );
}

export default function ProjectShow({...props}) {  
  return (
    <Show {...props} titleSource="email">
      <TabbedShowLayout>
        <Tab label="Загальне">
          <TextField source="id" />
          <TextField source="email" />
          <TextField source="fullName" label="ПІБ" />
          <TextField source="phone" label="Телефон" />
          <DateField source="emailConfirmedAt" label="Емейл підтверджений" />
          <FunctionField
            source="photo"
            render={(record: any) => <img width={100} height={100} src={record.photoUrl} alt="" />}
          />
          <AddressField source="residentialAddress" label={"Адреса прописки"} />
          <AddressField source="actualResidentialAddress" label={"Адреса фактичного місця проживання"} />
          <TextField source="bankName" label="Банк" />
          <TextField source="bankIBAN" label="IBAN" />

          <TextField source="ipn" label="ІПН" />
          <TextField source="passportNumber" label="Паспорт (серія)" />
          <TextField source="issuingAuthority" label="Ким виданий" />
        </Tab>
        <Tab label={"Компанії"}>
          <ReferenceManyField reference="companies" target="userId" label={false}>
            <Datagrid>
              <TextField source="name" label="Назва" />
              <TextField source="ipn" label="ІПН" />
              <DateField source="registeredAt" label="Зареєстрована" />              
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label={"Проекти"}>
          <ReferenceManyField reference="projects" target="userId" label={false}>
            <Datagrid>
              <TextField source="name" label="Назва" />
              <ReferenceField reference="companies" source="companyId" >
                <TextField source="name"/>
              </ReferenceField>
              <ActivateSwitch source="confirmed" />
              <TextField source="loanTerm" label="Термін (міс)" />
              <FunctionField
                source="loanAmount"
                label="Сума позики"
                render={(record: RaRecord) => (
                  <>
                    <strong>{record.loanAmount} $</strong>&nbsp; (<em>{record.minLoanAmount} $</em>)
                  </>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
