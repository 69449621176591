import { stringify } from 'query-string';
import { fetchJson as httpClient, Response } from "./fetch"
import { mapKeys, each, values, isObjectLike, fromPairs, toPairs } from "lodash"
import { CreateParams, CreateResult, DataProvider, DeleteManyParams, DeleteManyResult, DeleteParams, DeleteResult, GetListParams, GetListResult, GetManyParams, GetManyReferenceParams, GetManyResult, GetOneParams, GetOneResult, UpdateManyResult, UpdateParams, UpdateResult } from 'react-admin';

async function responseMany(response: Promise<Response>) {
  const { json: data, headers } = await response
  const keyField = headers.get("X-Key-Field") || "id"
  return ({
    data: data.map((x: any) => ({ ...x, id: x[keyField] || x["uuid"] || x["id"] })),
    total: parseInt(headers.get("X-Total-Count") ?? data.length)
  })
}
async function responseOne(response: Promise<Response>): Promise<GetOneResult> {
  const { json: data, headers } = await response
  const keyField = headers.get("X-Key-Field") || "id"
  return { data: { ...data, id: data[keyField] || data["uuid"] || data["id"] } }
}
async function responseRaw(response: Promise<Response>) {
  const { json } = await response
  return ({ data: json })
}

function queryPagination(params: GetManyReferenceParams | GetListParams): { limit?: number, offset?: number } {
  const { page, perPage } = Object.assign({ page: 0, perPage: 100 }, params.pagination || {})
  return (perPage ? {
    limit: perPage,
    offset: page > 0 ? (page - 1) * perPage : 0
  } : {})
}

function querySort(params: GetManyReferenceParams | GetListParams): { orderBy?: string } {
  const { field, order } = params.sort || {}
  return field ? { orderBy: `${field}:${order}` } : {}
}

function queryFilter(filter: any): any {
  return isObjectLike(filter) ? fromPairs(toPairs(filter).map(([key, value]) => [`filter.${key}`, value])) : {}
}

function isFile(value: { rawFile: File }): boolean {
  return value && value.rawFile && (value.rawFile instanceof File)
}

function flat(data: any): Record<string, any> {
  const res: Record<string, any> = {}
  each(data, (value, key) => {
    if (typeof (value) === "object" && !isFile(value)) {
      Object.assign(res, mapKeys(flat(value), (val, k) => `${key}.${k}`))
    } else {
      res[key] = value
    }
  })
  return res
}

function multipartBody(params: any): FormData {
  const data = flat(params)
  const formData = new FormData()
  Object.keys(data).map(async key => {
    const value = data[key]
    if (isFile(value)) {
      formData.append(key, value.rawFile)
    } else {
      formData.append(key, value)
    }
  })
  formData.append("_json", JSON.stringify(params))
  return formData
}
function hasFiles(params: any) {
  const data = flat(params)
  return values(data).filter(value => isFile(value)).length > 0
}

export default function dataProvider(apiUrl: string): DataProvider {
  return ({
    async fetch(path: string, params = {}, options = {}): Promise<any> {
      return responseRaw(httpClient(`${apiUrl}/${path}?${stringify(params)}`, options))
    },
    async execute(path: string, params = {}, options = {}): Promise<any> {
      const multipart = hasFiles(params)
      const body = multipart ? multipartBody(params) : JSON.stringify(params)
      return await responseRaw(httpClient(`${apiUrl}/${path}`, {
        method: 'POST',
        multipart,
        body,
        ...options
      }))
    },
    async getList(resource: string, params: GetListParams): Promise<GetListResult> {
      const query = {}
      Object.assign(query, queryPagination(params))
      Object.assign(query, querySort(params))
      Object.assign(query, queryFilter(params.filter))
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return await responseMany(httpClient(url))
    },
    async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
      return responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`))
    },
    async getMany(resource: string, params: GetManyParams): Promise<GetManyResult> {
      const filter = params.meta?.filter ?? {}
      const query = queryFilter({ ...filter, ids: params.ids.join(",") });
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return responseMany(httpClient(url))
    },
    async getManyReference(resource: string, params: GetManyReferenceParams): Promise<GetManyResult> {
      const query: Record<string, any> = {}
      Object.assign(query, queryPagination(params))
      Object.assign(query, querySort(params))
      Object.assign(query, queryFilter(params.filter))
      query["filter." + params.target] = params.id
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return responseMany(httpClient(url))
    },
    async update(resource: string, params: UpdateParams): Promise<UpdateResult> {
      const multipart = hasFiles(params.data)
      const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data)
      return responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PATCH',
        multipart,
        body,
      }))
    },
    async updateMany(resource: string, params: any): Promise<UpdateManyResult> {
      const responses = await Promise.all(params.ids.map((id: string) => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.data),
      })))
      return ({ data: responses.map(({ json }) => json.id) })
    },
    async create(resource: string, params: CreateParams): Promise<CreateResult> {
      return responseOne(httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }))
    },
    async delete(resource: string, params: DeleteParams): Promise<DeleteResult> {
      return responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`, { method: 'DELETE', }))
    },
    async deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
      const responses = await Promise.all(params.ids.map((id: string) => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE'
      })))
      return ({ data: responses.map(({ json }) => json.id) })
    }
  })
}