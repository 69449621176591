import { useState } from 'react';
import { DashboardMenuItem, MenuItemLink, MenuProps, UserIdentity, useSidebarState } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings"
import SubMenu from './SubMenu';
import { useAccount } from "../hooks"
import { items, MenuItemConfig } from "../config/menu"
import { MouseEventHandler } from 'react';

function renderItems(account: UserIdentity, items: MenuItemConfig[], onMenuClick: MouseEventHandler) {
  return account
    ? items.map((item) => (
        <MenuItemLink
          key={item.name}
          to={`/${item.name}`}
          primaryText={item.title}
          leftIcon={item.icon}
          onClick={onMenuClick}
        />
      ))
    : [];
}

export default function MyMenu({ onMenuClick, logout, ...props }: MenuProps) {
  const [sidebarIsOpen] = useSidebarState()
  
  const [isOpen, setIsOpen] = useState(false)
  const account = useAccount()

  const configItems = renderItems(account, items.filter(item => item.group === "config"), onMenuClick)
  return <div>
    &nbsp;
    <DashboardMenuItem dense={false} onClick={onMenuClick} />
    <>
      {renderItems(account, items.filter(item => item.group === undefined), onMenuClick)}
    </>    
    {configItems.length > 0 &&
      <SubMenu
        handleToggle={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        sidebarIsOpen={sidebarIsOpen}
        name="Config"
        icon={<SettingsIcon />}
      ><>{configItems}</>
      </SubMenu>
    }
  </div>
}
