import { keys, map, sortBy, pick, merge, intersection, get } from "lodash"
import { RaRecord } from "react-admin"

type GridDataEntry = RaRecord & {_id: string}
export function getGridData(data: Record<string, any>, assigned: Record<string, any>, defaults = {}): GridDataEntry[] {
  return sortBy(map(data, (meta, key) => merge({
    _id: key,
    testName: key,
    ...defaults,
    ...pick(meta, keys(defaults)),
    meta,
  }, assigned[key])), "id")
}

export function redirectPath(path: string | null = null) {
  let [, , redirect,] = document.location.href.match(/(\?|&)redirect=(.+)(&|$)/) || []
  return redirect || path || ""
}
export function checkPermissions(permissions: string[], ...required: string[]) {
  return intersection(permissions, required).length > 0
}

export function valueOf(record: RaRecord, path: string | Function): any {
  return typeof (path) === "function" ? path(record) : get(record, path)
}

export function round(value: number, decimals: number) {
  return parseFloat(Math.round(parseFloat(value + "e" + decimals)) + "e-" + decimals)
}
export function scaledValue(value: number, scale: number): number | null {
  if (value != null && scale != null && !isNaN(value) && !isNaN(scale)) {
    return round(value * scale, 2)
  }
  return null
}

