import { useState, useCallback, useEffect, useRef } from "react"
import { isEqual, every, isUndefined } from "lodash"
import { useAuthProvider } from "react-admin";
import { Profile } from "../lib/authProvider";
import { useNavigate } from "react-router"
const useAccount = (): Profile => {
  const authProvider = useAuthProvider()
  const [account, setAccount] = useState(authProvider.getProfile())
  const updateState = useCallback(() => setAccount(authProvider.getProfile()), [authProvider, setAccount])

  useEffect(() => {
    authProvider.addListener("profiles", updateState)
    return () => authProvider.removeListener("profiles", updateState)
  }, [authProvider, updateState])

  return account
}
const useProfiles = () => {
  const authProvider = useAuthProvider()
  const [profiles, setProfiles] = useState(authProvider.getProfiles())

  useEffect(() => {
    authProvider.addListener("profiles", setProfiles)
    return () => authProvider.removeListener("profiles", setProfiles)
  }, [authProvider, setProfiles])

  return profiles
}

const useStateToggle = (defaultValue: boolean): [boolean, () => void] => {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue(!value), [value, setValue])
  return [value, toggle]
}

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value;
  })
  return ref.current;
}

const useLoadingKey = (deps: any) => {
  const oldDeps = usePrevious(deps)
  const [key, setKey] = useState("loading")
  useEffect(() => {
    if (!isEqual(oldDeps, deps) && every(deps, dep => !isUndefined(dep))) {
      setKey("loaded")
    }
  }, [oldDeps, deps, setKey])

  return key
}
export {
  useLoadingKey,
  useAccount, 
  useStateToggle,
  useProfiles,
  useNavigate
}
