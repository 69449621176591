import React from "react"
import { Link, UserMenu, Button, UserMenuProps, useLogout } from "react-admin";
import {MenuItem} from '@mui/material';
import UserCard from "./components/UserCard";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew"
import LoginIcon from '@mui/icons-material/Fingerprint';
import _ from "lodash"
import ProfileImage from "./components/ProfileImage"
import { useProfiles } from "../hooks";
import { CSSProperties } from "@mui/material/styles/createTypography";

const classes: Record<string, CSSProperties> = ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-around",
  },

})

const Logout = () => {
  const logout = useLogout()

  return <div style={classes.footer}>
    <Link to="/login">
      <Button label="Login" ><LoginIcon /></Button>
    </Link>
    <Button label="Logout" onClick={logout}><LogoutIcon /></Button>
  </div>
}

export default function MyUserMenu(props: UserMenuProps) {
  const profiles = useProfiles()
  const account = profiles[0]
  
  return <UserMenu {...props} icon={<ProfileImage profile={account} variant="menu" />}>
    {_.map(profiles, profile =>
      <MenuItem key={profile.id} >
        <UserCard profile={profile} account={account} {...props} />
      </MenuItem>
    )}
    <hr />
    <Logout />
  </UserMenu>
}
