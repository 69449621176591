import React, { ComponentType, ReactElement } from "react"
import {Resource, ResourceElement}  from "react-admin"
import * as Users from "../resources/User"
import * as Projects from "../resources/Project";

import { checkPermissions } from "../lib/helper"
type ResourceType = "list" | "create" | "edit" | "show"

type ResourceForParams = {
  rest: {
    list?: ComponentType<any> | ReactElement;
    create?: ComponentType<any> | ReactElement;
    edit?: ComponentType<any> | ReactElement;
    show?: ComponentType<any> | ReactElement;
  },
  permissions: string[]
  access: {
    list?: string[]
    create?: string[]
    edit?: string[]
    show?: string[]
  }
}
function resourceProps({ rest, permissions, access = {} }: ResourceForParams) {
  const hasAccess = (type: ResourceType) => access[type] && permissions ? checkPermissions(permissions, ...(access[type] || [])) : true
  const resource = (type: ResourceType) => (rest[type] && hasAccess(type)) ? rest[type] : undefined
  return ({ 
      show: resource("show"),  
      list: resource("list"),
      edit: resource("edit"),
      create: resource("create") 
    })
}


export default function resources(permissions: any): ResourceElement[] {
  return (
    permissions && [
      <Resource key={"users"} name="users" {...resourceProps({ rest: Users, permissions, access: {} })} />,
      <Resource key={"projects"} name="projects" {...resourceProps({ rest: Projects, permissions, access: {} })} />,
      <Resource key={"projects"} name="projects" />,
    ]
  );  
}