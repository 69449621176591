import React from "react"
import { useRecordContext, useResourceContext } from "react-admin"
import { get } from "lodash"

type ResourceTitleProps = {
  source: string  | Function  
}

export default function ResourceTitle({ source }: ResourceTitleProps) {
  const resource = useResourceContext()
  const record = useRecordContext()
  const resourceTitle = resource
  const fetch = typeof source == "function" ? ((record: any) => source(record)) : ((record: any) => get(record, source))
  return record && record.id !== undefined ?
    (<span>{resourceTitle}: {fetch(record)} </span>) :
    (<span>{`Create ${resource}`}</span>)
}
