import { DateField, FunctionField, ReferenceManyField, Tab, TabbedShowLayout, TextField, useRecordContext } from "react-admin"
import { Show } from "../../components/DefaultDetails"
import { Typography } from "@mui/material";
import { ProjectsGrid } from "../Project/components/ProjectsGrid";

function AddressField({source}: {source: string, label?: string | boolean}) {
  const record = useRecordContext()
  const {city, region, street, house} = record[source] ?? {}
  return (
    <Typography variant="body2">{`${city} (${region}), ${street}, ${house}`}</Typography>
  );
}

export default function UserShow({...props}) {  
  return (
    <Show {...props} titleSource="email">
      <TabbedShowLayout>
        <Tab label="Загальне">
          <TextField source="id" />
          <TextField source="email" />
          <TextField source="fullName" label="ПІБ" />
          <TextField source="phone" label="Телефон" />
          <DateField source="emailConfirmedAt" label="Емейл підтверджений" />
          <FunctionField
            source="photo"
            render={(record: any) => <img width={100} height={100} src={record.photoUrl} alt="" />}
          />
          <AddressField source="residentialAddress" label={"Адреса прописки"} />
          <AddressField source="actualResidentialAddress" label={"Адреса фактичного місця проживання"} />
          <TextField source="bankName" label="Банк" />
          <TextField source="bankIBAN" label="IBAN" />

          <TextField source="ipn" label="ІПН" />
          <TextField source="passportNumber" label="Паспорт (серія)" />
          <TextField source="issuingAuthority" label="Ким виданий" />
        </Tab>
        <Tab label={"Проекти"}>
          <ReferenceManyField reference="projects" target="userId" label={false}>
            <ProjectsGrid />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
