import React, { ReactElement, ReactNode } from "react"
import { Create, List, Edit, Show, ShowProps, ListProps, EditProps, CreateProps} from "react-admin"
import DefaultActions from "./DefaultActions";
import ResourceTitle from "./ResourceTitle";


const DefaultShow = (props: ShowProps & { titleSource: string, }) => {
  const { titleSource, ...rest} = props
  return (<Show
      title={<ResourceTitle source={titleSource || "id"}/>}
      actions={<DefaultActions />}      
      {...rest}  />)
}
const DefaultList = ({ readOnly, ...props }: ListProps & { readOnly?: boolean}) => {
  return <List 
    hasCreate={false} 
    hasEdit={false}
    hasList={false}
    hasShow={false}
    perPage={100} bulkActionButtons={false}
    actions={<DefaultActions readOnly={readOnly} />}
    sx={{ padding: 0 }}
    {...props}
    ><>
      {props.children}
    </>
  </List>
}

const DefaultEdit = (props: EditProps & { children: ReactNode, titleSource?: string, noRefresh?: boolean }) => {
  const { titleSource, noRefresh, ...rest} = props
  return (<Edit
      mutationMode="pessimistic"
      title={<ResourceTitle source={titleSource || "id"}/>}      
      actions={<DefaultActions noRefresh={noRefresh}/>}      
      {...rest}
    />)
}
const DefaultCreate = (props: CreateProps & { children: ReactElement, titleSource: string, noRefresh?: boolean }) => {
  const { titleSource, ...rest} = props
  return (<Create
      title={<ResourceTitle source={titleSource || "id"}/>}
      actions={<DefaultActions/>}
      {...rest}
      />)
}


export {DefaultCreate as Create}
export {DefaultEdit as Edit}
export {DefaultShow as Show}
export {DefaultList as List}
