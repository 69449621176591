import React, { useCallback } from "react"
import { useNotify, useRecordContext, useResourceContext, useUpdate } from "react-admin"
import { Switch, FormControlLabel } from '@mui/material';
import { useStateToggle } from "../../hooks";

interface ActivateSwitchProps {
  source: string;
  label?: string
}
export default function ActivateSwitch({source}: ActivateSwitchProps) {
  const record = useRecordContext();
  const resource = useResourceContext();
  const [active, toggle] = useStateToggle(record[source]);
  const notify = useNotify();
  const onSuccess = useCallback(
    (data: any) => notify(data[source] ? "Confirmed" : "Unconfirmed"),
    [notify, source]  
  );
  const [update] = useUpdate(
    resource,
    { id: record.id, data: { [source]: !active } },
    { mutationMode: "pessimistic", onSuccess }
  );

  const onChange = useCallback(() => {
    toggle();
    update();
  }, [toggle, update]);

  return <FormControlLabel label="" control={<Switch checked={active} onChange={onChange} />} />;
}