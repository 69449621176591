import { List } from "../../components/DefaultDetails"
import { ProjectsGrid } from "./components/ProjectsGrid";

export default function ProjectList({...props})  {  
  return (
    <List {...props}>
      <ProjectsGrid />
    </List>
  );
}
