import React, {  ReactElement } from 'react';
import UsersIcon from "@mui/icons-material/AccountBox"
import ProjectsIcon from "@mui/icons-material/AccountBalance";

export type MenuItemConfig = {
    title: string,
    name: string,
    icon: ReactElement
    group?: string
}
const items: MenuItemConfig[] = [
  { title: "Користувачі", name: "users", icon: <UsersIcon /> },
  { title: "Проекти", name: "projects", icon: <ProjectsIcon /> },
];
export { items }
