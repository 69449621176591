import { useCallback } from 'react';
import { useAuthProvider } from "react-admin"
import { useNavigate } from "react-router";
import ProfileImage from "./ProfileImage"

import { Card, CardContent, Theme, Typography } from '@mui/material';
import { Profile } from '../../lib/authProvider';

const classes = ({
  card: {
    display: 'flex',
    width: 300,
  },
  cardActive: {
    display: 'flex',
    width: 300,
    backgroundColor: "#EEE"
  },
  cover: {
    margin: "10px 10px",
    width: 64,
    height: 64,
  },  
  user: {
    whiteSpace: "normal"
  },
  content: {
    flex: '1 0 auto',
    justifyContent: "flex-start",
  },
  controls: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    justifyContent: "flex-end",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  })
})

export default function UserCard({ account, profile }: { account: Profile, profile: Profile }) {
  const navigate = useNavigate()
  const authProvider = useAuthProvider()
  
  const active = account && account.id === profile.id
  
  const loginAs = useCallback((profile: Profile) => () => {
    if (!active) {
      authProvider.setActiveAccount(profile.token)
      navigate("/", {state: { id: profile.id }})
    }
  }, [authProvider, navigate, active])

  return <Card sx={active ? classes.cardActive : classes.card} elevation={1} onClick={loginAs(profile)}>
    <ProfileImage profile={profile} variant="profile" />
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: "70%"
      }}>
      <CardContent sx={classes.content}>
        <Typography component="h5" variant="subtitle1" color={active ? "primary" : "inherit"}>{profile.fullName}</Typography>
        <Typography sx={classes.user} variant="subtitle1" color="textSecondary">{profile.email}</Typography>
      </CardContent>            
    </div>
  </Card>
}
