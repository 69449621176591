import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5"
    },
    secondary: {
      main: '#0044ff',
      contrastText: '#ffcc00',
    },
    
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#3f51b5"
        }
      }      
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 48
        }
      }      
    },
    MuiMenu: {
     styleOverrides: {
        paper: {
          maxHeight: "100%"
        }
     }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: "250px"
        }
      }
    },    
  },
 
});
export default defaultTheme