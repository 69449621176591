import React from "react";
import { Button, Datagrid,   NumberField, TextField, useDataProvider, useNotify, useRecordContext } from "react-admin";
import { Card, CardContent, CardMedia, IconButton, Popover, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { get } from "lodash";
function ExpandProject() {
  const record = useRecordContext();
  const url = get(record, "photos[0].uri"); ;
  return (
    <>
      <Card sx={{ maxWidth: 500 }}>
        {url && <CardMedia component="img" image={url} alt="" />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {record.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {record.fullDescription}
          </Typography>
        </CardContent>
      </Card>      
    </>
  );  
}

function CompanyField() {
  const { companyInfo } = useRecordContext()
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableAutoFocus>
        <Card sx={{ maxWidth: 345 }}>
          {companyInfo.photo?.uri && <CardMedia component="img" image={companyInfo.photo?.uri} alt="" />}
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {companyInfo.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Registered at {companyInfo.registeredAt ? new Date(companyInfo.registeredAt).toDateString() : ""}
            </Typography>
          </CardContent>
        </Card>
      </Popover>
      <TextField source="companyInfo.name" />
      <IconButton onClick={handlePopoverOpen}>
        <InfoIcon />
      </IconButton>
    </>
  ); 
}

function ActivateButton() {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const onClick = () => {
    dataProvider
      .update("projects", {
        id: record.id,
        data: { status: "ACTIVE" },
        previousData: undefined,
      })
      .then(() => notify("Активовано", {type: "success"}));
  }
  return record.status === 'CREATED' ?  <Button onClick={onClick} label="Активувати" /> : <span />
}

export function ProjectsGrid() {
  return (
    <Datagrid
      expand={<ExpandProject />}
      sx={{
        ".column-description": {
          maxWidth: 300,
        },
      }}>
      <TextField source="name" label="Назва" />
      <TextField source="description" label="Опис" />
      <CompanyField />
      <NumberField
        source="loanAmount"
        label="Сума займу"
        locales={["en-US"]}
        options={{ style: "currency", currency: "USD", maximumFractionDigits: 0 }}
      />
      <NumberField
        source="minLoanAmount"
        label="Мінімальна сума"
        locales={["en-US"]}
        options={{ style: "currency", currency: "USD", maximumFractionDigits: 0 }}
      />
      <NumberField source="loanPercentage" label="Процент" options={{ style: "unit", unit: "percent" }} />
      <NumberField
        source="loanTerm"
        label="Термін (міс)"
        options={{ style: "unit", unit: "month" }}
        locales={"uk-UA"}
      />
      <TextField source="status" label="Статус" />
      <ActivateButton />
    </Datagrid>
  );
}
