import {Datagrid, DateField, TextField} from "react-admin"
import { List } from "../../components/DefaultDetails"
import ShowLink from "../../components/ShowLink"
import ActivateSwitch from "../Common/ActivateSwitch"

export default function UsersList({...props})  {  
  return <List {...props} hasCreate>
      <Datagrid >
        <ShowLink source="email" />
        <TextField source="phone"/>        
        <ActivateSwitch source="confirmed"/>
        <DateField source="emailConfirmedAt" />
      </Datagrid>
    </List>
}
